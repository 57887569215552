<template>
  <div class="d-flex flex-column flex-grow-1">
    <div
      v-if="client"
      class="d-flex align-items-center sticky bg-white border-bottom p-3 nav-header-container"
    >
      <div class="d-flex align-items-center nav-header">
        <UserProfileBadge :user="client"></UserProfileBadge>

        <div class="btn-group ms-3">
          <a
            @click="setActivePanelIndex(panelIndex)"
            v-for="(panel, panelIndex) in panels"
            :key="`key-${panelIndex}`"
            :tabindex="panelIndex"
            :class="{ 'active': activePanelIndex === panelIndex }"
            class="btn btn-outline-primary"
          >
            <fa-icon :icon="['fas', panel.icon]"></fa-icon>
          </a>
        </div>
      </div>

      <div class="m-0 ms-3 h5">{{ panels[activePanelIndex].name }}</div>

      <div v-if="activePanelIndex === 0" class="d-flex align-items-center flex-grow-1 ms-3">
        <CalendarViewModeConfig></CalendarViewModeConfig>

        <CalendarDateSelector
          @dateSelected="selectedDate = $event"
          :selectedDate="selectedDate"
          :currentDate="today"
          class="ms-auto"
        ></CalendarDateSelector>
      </div>
    </div>

    <div v-if="client" class="d-flex flex-column flex-grow-1">
      <Calendar
        v-if="activePanelIndex === 0"
        :userId="clientId"
        :selectedDate="selectedDate"
      ></Calendar>
      <Nutrition
        v-if="activePanelIndex === 1"
        :userId="clientId"
      ></Nutrition>
      <ClientMetric
        v-if="activePanelIndex === 2"
        :userId="clientId"
      ></ClientMetric>
      <ClientProfile
        v-if="activePanelIndex === 3"
        :userId="clientId"
      ></ClientProfile>
    </div>

    <div v-if="error" class="container-fluid">
      <div class="row">
        <div class="col">
          <ErrorMessage :error="error" class="m-3"></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { httpGet } from '@/core/http';

export default {
  name: 'Client',
  components: {
    UserProfileBadge: () => import('@/components/UserProfileBadge'),
    Calendar: () => import('@/components/calendar/Calendar'),
    CalendarViewModeConfig: () => import('@/components/config/CalendarViewModeConfig'),
    CalendarDateSelector: () => import('@/components/calendar/CalendarDateSelector'),
    Nutrition: () => import('@/components/nutrition/Nutrition'),
    ClientProfile: () => import('@/components/ClientProfile'),
    ClientMetric: () => import('@/components/ClientMetric'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
  },
  methods: {
    async fetchUser() {
      this.error = null;
      try {
        const { data: { client } } = await httpGet(`/coaching/client/${this.clientId}`);
        this.client = client;
      } catch (err) {
        this.error = err;
      }
    },
    setActivePanelIndex(index) {
      const panel = this.panels[index];
      window.location.hash = panel.name.toLowerCase();
      window.scrollTo(0, 0);
      this.activePanelIndex = index;
    },
  },
  data() {
    return {
      panels: [
        { name: 'Calendar', icon: 'calendar-week' },
        { name: 'Nutrition', icon: 'utensils' },
        { name: 'Metric', icon: 'chart-line' },
        { name: 'Profile', icon: 'user-circle' },
      ],
      activePanelIndex: 0,
      client: null,
      error: null,
      selectedDate: dayjs(),
    };
  },
  created() {
    document.querySelector('html').classList.add('fullscreen');
    const hash = window.location.hash.replace('#', '');
    switch (hash) {
      case 'nutrition':
        this.activePanelIndex = 1;
        break;
      case 'metric':
        this.activePanelIndex = 2;
        break;
      case 'profile':
        this.activePanelIndex = 3;
        break;
      default:
        this.activePanelIndex = 0;
        break;
    }
  },
  destroyed() {
    document.querySelector('html').classList.remove('fullscreen');
  },
  mounted() {
    this.fetchUser();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.nav-header-container {
  z-index: 1105;
}

.nav-header {
  position: relative;
  padding-right: 2.25rem;

  &::before, &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 1.5rem;
    background-color: $borderColor;
    pointer-events: none;
  }

  &::before {
    bottom: 50%;
    transform-origin: 100% 100%;
    transform: rotate(-30deg);
  }

  &::after {
    top: 50%;
    transform-origin: 100% 0%;
    transform: rotate(30deg);
  }
}
</style>
